<template>
  <div class="page-container">
    <div class="header-body">
      <el-card>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-input
                placeholder="请输入姓名"
                class="input-with-select"
                clearable
                v-model.trim="queryInfo.name"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="searchHandle"
                ></el-button>
              </el-input>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="table-body">
      <el-card>
        <el-table :data="studentList" style="width: 100%" border stripe>
          <el-table-column type="index" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="orderNum" label="订单号"> </el-table-column>
          <el-table-column prop="name" label="姓名" width="100">
          </el-table-column>
          <el-table-column prop="schoolName" label="报名院校">
          </el-table-column>
          <el-table-column prop="majorName" label="报名专业"> </el-table-column>
          <el-table-column prop="openId" label="微信openid"> </el-table-column>
          <el-table-column prop="price" label="报名费" width="100">
          </el-table-column>
          <el-table-column prop="gmtCreate" label="创建时间"> </el-table-column>
        </el-table>
      </el-card>
    </div>
    <div class="pagination-body">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getReadySignUpList } from '@/api/api'

export default {
  data () {
    return {
      studentList: [],
      previewDialog: false,
      queryInfo: {
        currentPage: 1,
        pageSize: 10,
        orderNum: '',
        name: ''
      },
      total: 0
    }
  },
  created () {
    this.getReadySignUpList()
  },
  methods: {
    /* 获取订单列表 */
    getReadySignUpList () {
      getReadySignUpList(this.queryInfo).then(res => {
        this.studentList = res.data.list
        this.total = res.data.total
      })
    },
    /* 根据订单号搜索 */
    searchHandle () {
      this.queryInfo.currentPage = 1
      this.getReadySignUpList()
    },
    /* 分页选择 */
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getReadySignUpList()
    },
    handleCurrentChange (size) {
      this.queryInfo.currentPage = size
      this.getReadySignUpList()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .pagination-body {
    .el-pagination {
      text-align: center;
      padding: 20px 0;
    }
  }
}
</style>

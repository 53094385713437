import request from '@/utils/service/request'

/* 分页获取学校列表 */
const getSchoolList = (params) => {
  return request.post('school/page', params)
}

/* 分页获取专业列表 */
const getMajorList = (params) => {
  return request.post('major/page', params)
}

/* 添加专业 */
const addMajor = (params) => {
  return request.post('major/add', params)
}

/* 删除专业 */
const deleteMajor = (params) => {
  return request.post('major/del/' + params)
}

/* 修改专业 */
const updateMajor = (params) => {
  return request.post('major/update', params)
}

/* 根据id获取专业信息 */
const getMajorInfoById = (params) => {
  return request.post('major/get/' + params)
}

const updateMarjorDisable = (params) => {
  return request.post('major/disable', params)
}
/* 添加院校 */
const addSchool = (params) => {
  return request.post('school/add', params)
}

/* 删除院校 */
const deleteSchool = (params) => {
  return request.post('school/del/' + params)
}

/* 通过id获取学校信息 */
const getSchoolInfoById = (params) => {
  return request.post('school/get/' + params)
}

/* 根据学校id获取学校专业 */
const getMajorBySchoolId = (params) => {
  return request.post('school/getMajor/' + params)
}

/* 更新学校信息 */
const updateSchool = (params) => {
  return request.post('school/update', params)
}

/* 更新禁用状态 */
const updateDisable = (params) => {
  return request.post('school/disable', params)
}
/** ********************************招生老师*****************************/
/* 新增推广员 */
const addPromoter = (params) => {
  return request.post('promoter/add', params)
}

/* 删除推广员 */
const deletePromoter = (params) => {
  return request.post('promoter/del/' + params)
}

/* 通过id获取推广员信息 */
const getPromoterInfoById = (params) => {
  return request.post('promoter/get/' + params)
}
/* 通过用户id获取推广员人 */
const getListByUserId = (params) => {
  return request.post('promoter/listByUserId/' + params)
}

/* 分页获取推广员信息 */
const getPromoter = (params) => {
  return request.post('promoter/page', params)
}

/* 更新推广员信息 */
const updatePromoter = (params) => {
  return request.post('promoter/update', params)
}

/* 获取推广员列表 */
const getPromoterUserList = (params) => {
  return request.post('promoter/pageList', params)
}

// 获取学校推广人数
const getSchoolSignUpTotal = (params) => {
  return request.post('/promoter/listBySch', params)
}

/* 上传文件 */
const uploadFile = (params) => {
  return request.post('file/upload')
}

/* 根据id获取学生信息 */
const getStudentInfoById = (params) => {
  return request.post('student/get/' + params)
}

/* 更新学生信息 */
const updateStudent = (params) => {
  return request.post('student/update', params)
}

/* 删除学生信息 */
const deleteStudent = (params) => {
  return request.post('student/del/' + params)
}

// 筛选学生信息
const filterStudentHttp = (params) => {
  return request.post('student/pageWithCond', params)
}

// 更改报名时间
const updateCreateTime = (params) => {
  return request.post('student/updateCreateTime', params)
}
// 更改学生的推广员
const updatePromoterToStu = (params) => {
  return request.post('student/updatePromoter', params)
}

/* 获取订单列表 */
const getOrder = (params) => {
  return request.post('/order/pageWithCond', params)
}

/* 生成小程序码 */
const getMiniQRcode = (params) => {
  return request.post('wx/generateQRCode', params)
}

/* 到处excel */
const exportExecl = (params) => {
  return request.post('student/exportExecl', { responseType: 'blob' }, params)
}

/* 获取公司资源 */
const getCompanyResource = (params) => {
  return request.post('company/pageInfo', params)
}

/* 导入公司资源excel */
const importCompanyExcel = (params) => {
  return request.post('company/importExecl', params)
}

/* 通过手机号查询 */
const getInfoByPhone = (params) => {
  return request.post('company/getInfoByPhone', params)
}

/* 下载模板 */
const downloadTemplate = (params) => {
  return request.get('company/downloadTemplate', params)
}

/* 导出公司资源excel */
const exportCompanyExcel = (params) => {
  return request.post('compamy/exportExecl', params)
}

/* 添加报名备注 */
const updateRemark = (params) => {
  return request.post('student/updateRemark', params)
}

/* 获取学费订单 */
const getLearnPayList = (params) => {
  return request.post('learnPay/page', params)
}

/* 删除学费订单 */
const deleteLearnPay = (params) => {
  return request.post('learnPay/del/' + params, params)
}

/* 删除报名订单 */
const deleteSignupOrder = (params) => {
  return request.post('order/del/' + params, params)
}

/* 导出报名订单 */
const exportOrder = (params) => {
  return request.post('order/exportOrder', params)
}

/* 获取预付学费列表 */
const getReadyLearnPayList = (params) => {
  return request.post('prepay/page', params)
}

/* 获取预付报名学费列表 */
const getReadySignUpList = (params) => {
  return request.post('preOrder/pageWithCond', params)
}

// 列表删除
const deleteCompanyResouse = (params) => {
  return request.post('/company/del/' + params, params)
}

// 获取参数列表
const getParamsList = (params) => {
  return request.post('/dict/v3/page', params)
}

// 添加参数
const addParams = (params) => {
  return request.post('/dict/v3/save', params)
}

// 删除参数
const deleteParams = (params) => {
  return request.delete('/dict/v3/del/' + params)
}

// 根据id获取参数信息
const getParamsById = (params) => {
  return request.get('/dict/v3/get/' + params)
}

// 根据key查询系统参数
const getSysParamsByKey = (params) => {
  return request.get('/dict/v3/listByDictKey?dictKey=' + params)
}

export {
  getSchoolList,
  getMajorList,
  addMajor,
  deleteMajor,
  updateMajor,
  getMajorInfoById,
  updateMarjorDisable,
  addSchool,
  deleteSchool,
  getSchoolInfoById,
  updateSchool,
  updateDisable,
  addPromoter,
  deletePromoter,
  getPromoterInfoById,
  getListByUserId,
  getPromoter,
  updatePromoter,
  uploadFile,
  getStudentInfoById,
  updateStudent,
  deleteStudent,
  getOrder,
  getMiniQRcode,
  filterStudentHttp,
  updateCreateTime,
  updatePromoterToStu,
  getMajorBySchoolId,
  exportExecl,
  getCompanyResource,
  importCompanyExcel,
  getInfoByPhone,
  downloadTemplate,
  exportCompanyExcel,
  updateRemark,
  getPromoterUserList,
  getLearnPayList,
  deleteLearnPay,
  deleteSignupOrder,
  getReadyLearnPayList,
  getReadySignUpList,
  deleteCompanyResouse,
  getSchoolSignUpTotal,
  getParamsList,
  addParams,
  deleteParams,
  getParamsById,
  getSysParamsByKey,
  exportOrder
}
